// eslint-disable-next-line deprecate/import
import lodashDebounce from 'lodash-es/debounce';

// eslint-disable-next-line deprecate/import
import type { DebounceSettings } from 'lodash-es/debounce';

export const DEFAULT_WAIT = 300;

/** Wrapper around _.debounce to enforce our DEFAULT_WAIT. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- The function signature used (including `any`) is a direct copy from Lodash's `debounce` signature.
export function debounce<T extends (...args: any) => any> (func: T, wait = DEFAULT_WAIT, options?: DebounceSettings) {
  return lodashDebounce(func, wait, options);
}
