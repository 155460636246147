import { assert } from 'cadenza/utils/custom-error';

/*
 * This alphabet uses a-z A-Z 0-9 _- symbols. It is much bigger than for a UUID and still doesn't
 * need any further encoding when used in an URL.
 */

const LETTER_ONLY_ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
const ALPHABET = LETTER_ONLY_ALPHABET + '0123456789_-';

const LETTERS_ONLY_ALPHABET_LENGTH = LETTER_ONLY_ALPHABET.length;
const ALPHABET_LENGTH = ALPHABET.length;

/**
 * Generates a random pseudo unique id string using a bigger alphabet than UUID that doesn't need any
 * further encoding for when used in an URL.
 * For usage as element ID it is ensured that the first character is a letter.
 *
 * @param length - The length of the string as positive integer greater 0
 * @return The id
 */
export function uniqueId (length = 21): string {
  assert(length > 0 && Number.isInteger(length), 'length must be a positive integer > 0', length);

  let id = '';
  const bytes = self.crypto.getRandomValues(new Uint8Array(length));

  // First character must be a letter to be usable as an id attribute value of Elements
  id += LETTER_ONLY_ALPHABET[bytes[--length] % LETTERS_ONLY_ALPHABET_LENGTH];

  while (length-- > 0) {
    id += ALPHABET[bytes[length] % ALPHABET_LENGTH];
  }
  return id;
}
