export function createSvgArc (x: number, y: number, r: number, startAngle: number, endAngle: number): string {
  if (startAngle > endAngle) {
    return createSvgArc(x, y, r, endAngle, startAngle);
  }

  const largeArc = endAngle - startAngle <= Math.PI ? 0 : 1;
  const arcStartX = (x + Math.cos(startAngle) * r).toFixed(2);
  const arcStartY = (y - Math.sin(startAngle) * r).toFixed(2);
  const arcEndX = (x + Math.cos(endAngle) * r).toFixed(2);
  const arcEndY = (y - Math.sin(endAngle) * r).toFixed(2);

  return [
    'M', x, y,
    'L', arcStartX, arcStartY,
    'A', r, r, 0, largeArc, 0, arcEndX, arcEndY,
    'L', x, y
  ].join(' ');
}

let EMPTY_DIV: HTMLDivElement;

/**
 * Creates an svg element containing the SVG markup received as argument.
 *
 * @param svgMarkup - Valid SVG markup, typically imported from a SVG file
 * @param role - The ARIA role to use for the SVG element
 * @return An svg element containing the SVG markup received as argument
 */
export function parseSvg (svgMarkup: string, role: ('img' | 'presentation') = 'presentation') {
  // For better performance (we have many icons), we use innerHTML,
  // which is four times faster than DOMParser.
  EMPTY_DIV = EMPTY_DIV || document.createElement('div');
  /* eslint-disable-next-line no-unsanitized/property */
  EMPTY_DIV.innerHTML = svgMarkup;
  const svgElement = EMPTY_DIV.firstElementChild as SVGSVGElement;
  svgElement.remove(); // Disconnect from the div
  svgElement.setAttribute('role', role);
  return svgElement;
}
