// https://developer.mozilla.org/en-US/docs/Web/API/DOMException
export class AbortError extends DOMException {

  constructor () {
    super('Aborted', 'AbortError');
    /*
     * If this is not nullified, printing the error will display the stack where this object was created,
     * not the stack where the exception was logged, which is confusing.
     */
    this.stack = null;
  }

}

/** @param {unknown} error */
export function isAbortError (error) {
  return error?.name === 'AbortError';
}
