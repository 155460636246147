import lodashThrottle from 'lodash-es/throttle';

import { DEFAULT_WAIT } from 'cadenza/utils/debounce';

/**
 * Wrapper around _.throttle to enforce our DEFAULT_WAIT
 *
 * @param {function} func
 * @param {number} wait
 * @param {ThrottleSettings} options
 * @returns {DebouncedFunc<*>}
 */
export function throttle (func, wait = DEFAULT_WAIT, options = undefined) {
  return lodashThrottle(func, wait, options);
}
