import { isAnimationEnabled } from 'cadenza/utils/animation-util';

interface ScrollIntoViewOptions {
  block: ScrollLogicalPosition;
  behavior: ScrollBehavior;
}

/**
 * streamlined variant of the native Element#scrollIntoView
 *
 * respects reduced motion setting
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
 */
export function scrollIntoView (el: Element, options?: Partial<ScrollIntoViewOptions>) {
  const {
    block = 'nearest',
    behavior = 'smooth'
  } = options ?? {};
  // eslint-disable-next-line no-restricted-syntax
  el.scrollIntoView({
    block,
    behavior: isAnimationEnabled() ? behavior : 'auto'
  });
}
