import h from 'hyperscript';
import $ from 'jquery';

import { chromiumMajorVersion, isChromiumBrowser } from 'cadenza/utils/browser';
import { assert } from 'cadenza/utils/custom-error';
import { on } from 'cadenza/utils/event-util';
import { debounce } from 'cadenza/utils/debounce';
import { throttle } from 'cadenza/utils/throttle';

import './scroll-shadow.css';

const SCROLL_COLUMN_CLASS = 'd-scroll-column';

/**
 * Adds shadows to the given scroll element's header and footer elements.
 * The shadows indicate scrollability in that direction and update on scroll.
 * legacySetupScrollShadow is still being used in a lot of places, for e.g. table.
 * Because of the complexity of the table, it is not easy to migrate to new scroll shadow
 * implementation.
 *
 * @param {HTMLElement} el - The scroll element
 * @param {HTMLElement} [header] - The header element
 * @param {HTMLElement} [footer] - The footer element
 * @return {Function} A function to call to update the scroll shadows. It should be called manually after the contents of the scroll container were changed, because it is quite hard to do automatically internally here, but very ease to call from the outside.
 * @deprecated Use `setupScrollShadow` instead
 */
export function legacySetupScrollShadow (el, header, footer) {
  if (header) {
    header.classList.add('d-scroll-header');
  }
  if (footer) {
    footer.classList.add('d-scroll-footer');
  }

  function onScroll () {
    if (header) {
      header.classList.toggle('has-shadow', el.scrollTop > 0);
    }
    if (footer) {
      footer.classList.toggle('has-shadow', el.clientHeight + el.scrollTop < el.scrollHeight);
    }
  }
  const onScrollThrottled = throttle(onScroll, 100);

  // Update once on initialization
  onScroll();

  // Update on main element scroll event
  el.addEventListener('scroll', onScrollThrottled, { passive: true });

  // Return the update function, so the initiator can call it manually on its internal events which can cause content resize.
  return onScrollThrottled;
}

/**
 * Adds shadows to the given fixed column element.
 * The shadows indicate scrollability in that direction and update on scroll.
 *
 * @param {HTMLElement} scrollElement - scroll element
 * @param {...HTMLElement} shadowTargets - HTML elements where shadow will be applied
 */
export function setupColumnScrollShadow (scrollElement, ...shadowTargets) {
  assert(shadowTargets, 'No shadow targets defined.');
  shadowTargets.forEach(target => target.classList.add(SCROLL_COLUMN_CLASS));

  onScrollLeft();
  scrollElement.addEventListener('scroll', onScrollLeft, { passive: true });

  function onScrollLeft () {
    const showShadow = scrollElement.scrollLeft > 0;
    shadowTargets.forEach(target => target.classList.toggle('has-shadow', showShadow));
  }
}

export function removeColumnScrollShadow (scrollElement) {
  const columns = scrollElement.querySelectorAll(`.${SCROLL_COLUMN_CLASS}`);
  [ ...columns ].forEach((column) => {
    column.classList.remove(SCROLL_COLUMN_CLASS);
  });
}

/**
 * Adds shadows to the specified element.
 *
 * @param {HTMLElement} element - Element on which shadows will appear
 * @param {HTMLElement} [scrollElement] - Element where scroll bar is located, if it's not defined,
 *   element is used
 * @return {() => void} - Returns function that can be used to force update.
 */
export function setupScrollShadow (
  element,
  scrollElement = element
) {
  // create shadow elements
  const shadowTop = h('.d-scroll-shadow.d-scroll-shadow--top');
  const shadowBottom = h('.d-scroll-shadow.d-scroll-shadow--bottom');

  // remove previous shadows
  scrollElement.parentElement?.querySelector('.d-scroll-shadow--bottom')?.remove();
  scrollElement.parentElement?.querySelector('.d-scroll-shadow--top')?.remove();

  // add new shadows
  scrollElement.after(shadowBottom, shadowTop);

  function updateShadowPositions () {
    // We need to make sure that we clean up event listener for resizing the window when the element
    // is removed from the DOM.
    if (!document.contains(scrollElement)) {
      window.removeEventListener('resize', debouncedUpdateShadowPositions);
    }

    const { width } = element.getBoundingClientRect();
    const { scrollHeight, scrollTop } = scrollElement;

    const jqueryScrollElement = $(scrollElement);
    const { top, left } = jqueryScrollElement.position();
    const height = jqueryScrollElement.outerHeight();

    Object.assign(shadowTop.style, {
      width: `${width}px`,
      top: `${top}px`,
      left: `${left}px`
    });

    Object.assign(shadowBottom.style, {
      width: `${width}px`,
      top: `${top + height - 4}px`,
      left: `${left}px`
    });

    shadowTop.classList.toggle(
      'is-visible',
      scrollTop > 0);

    shadowBottom.classList.toggle(
      'is-visible',
      Math.ceil(scrollTop + height) < scrollHeight);
  }

  const debouncedUpdateShadowPositions = debounce(updateShadowPositions);

  scrollElement.addEventListener('scroll', updateShadowPositions);
  const resizeObserver = new ResizeObserver(debouncedUpdateShadowPositions);
  resizeObserver.observe(element);

  debouncedUpdateShadowPositions();

  activateChromePrintingWorkaround(shadowTop, shadowBottom);

  return updateShadowPositions;
}

/**
 * Adds shadows to the specified element inside specified container. The shadow will not be shown if the element
 * container don't have a scrollbar or if scrollbar will reach bottom position.
 * This function is different from above one. It just adds shadow class to element instead of elements with shadow.
 * It's better in case of floating element because we don't have to sync element movment with added elements.
 * It is needed to determine if element with position: sticky has stopped.
 * Maybe it can be replaced in future with :stuck (not yet implemented).
 *
 *
 * @param {HTMLElement} element - Element on which shadows will appear
 * @param {HTMLElement} [scrollElement] - Container where scroll bar is located
 * @return {Function} - Returns function that can be used to force update.
 */
export function setupShadowOnStickyElement (
  element,
  scrollElement
) {
  if (!element || !scrollElement) { return; }
  const debouncedScrollCheck = throttle(checkIfScrolledDown);
  scrollElement.addEventListener('scroll', () => debouncedScrollCheck(element, scrollElement, debouncedScrollCheck));
  window.addEventListener('resize', () => debouncedScrollCheck(element, scrollElement, debouncedScrollCheck));
  debouncedScrollCheck(element, scrollElement, debouncedScrollCheck);
  return debouncedScrollCheck;
}

function checkIfScrolledDown (element, scrollElement, debouncedScrollCheck) {
  // We need to make sure that we clean up event listener for resizing the window when the element
  // is removed from the DOM.
  if (!scrollElement || !document.contains(scrollElement)) {
    window.removeEventListener('resize', debouncedScrollCheck);
  }
  const { scrollHeight, scrollTop } = scrollElement;
  const jqueryScrollElement = $(scrollElement);
  const height = jqueryScrollElement.outerHeight() ?? 0;

  element.classList.toggle(
    'd-sticky-shadow',
    Math.ceil(scrollTop + height) < scrollHeight);
}

/**
 * Workaround for Chrome 117 where, if a scroll shadow is in the bottom third of the page, e.g.
 * in the Data Browser if it contains at least one objecttype, printing in Chrome 117 is broken
 * (the bottom part of the workbook is repeated on a second page).
 * Firefox and also Chrome 116 don't need the workaround.
 */
function activateChromePrintingWorkaround (shadowTop, shadowBottom) {
  if (isChromiumBrowser && chromiumMajorVersion >= 117) {
    on(window, 'beforeprint', () => {
      shadowTop.style.display = 'none';
      shadowBottom.style.display = 'none';
    });
    on(window, 'afterprint', () => {
      shadowTop.style.display = '';
      shadowBottom.style.display = '';
    });
  }
}
