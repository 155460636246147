import h from 'hyperscript';

import { uniqueId } from 'cadenza/utils/unique-id';
import { DELAY_THRESHOLD } from 'cadenza/utils/promise-utils';

import './progress-spinner.css';

export const COMPONENT_NAME = 'd-progress-spinner';

/** @param vertical - align vertically */
export interface ProgressSpinnerOptions {
  label?: string | HTMLElement;
  labelVisible?: boolean;
  size?: 'xs' | 's';
  vertical?: boolean;
  initiallyHidden?: boolean;
}

export class ProgressSpinner extends HTMLElement {

  readonly #spinner: Element;
  readonly #label: HTMLElement;

  _promise?: Promise<unknown>;

  constructor ({ label, labelVisible = true, size, vertical, initiallyHidden }: ProgressSpinnerOptions = {}) {
    super();
    this.#spinner = h('.progress-spinner');
    this.#label = h('span.progress-spinner-label', { id: uniqueId() });
    if (label) {
      if (typeof label === 'string') {
        this.label = label;
      } else {
        this.#label.append(label as HTMLElement);
      }
    }

    if (!labelVisible) {
      this.#label.classList.add('visuallyhidden');
    }
    if (size) {
      this.classList.add(`d-progress-spinner-${size}`);
    }
    if (vertical) {
      this.classList.add('d-progress-spinner-column');
    }
    if (initiallyHidden) {
      this.hidden = true;
    }
  }

  connectedCallback () {
    this.id = uniqueId();
    this.classList.add(COMPONENT_NAME);
    this.setAttribute('role', 'progressbar');
    this.setAttribute('aria-labelledby', this.#label.id);
    this.innerHTML = '';
    this.append(this.#spinner, this.#label);

    if (!this.label) {
      this.label = this.getAttribute('label');
    }
  }

  show (promise: Promise<unknown>, label?: string) {
    // we show the spinner after 200ms (only has an effect if `initiallyHidden = true`) ...
    const timeout = setTimeout(() => { this.hidden = false; }, DELAY_THRESHOLD);

    promise.finally(() => {
      // ... then clear the above timeout on completion of the promise
      clearTimeout(timeout);
      if (this._promise === promise) {
        this.hidden = true;
      }
    });
    this._promise = promise;

    if (label != null) {
      this.label = label;
    }
  }

  set label (value) {
    this.#label.textContent = value;
    this.#label.hidden = !this.label;
  }

  get label () {
    return this.#label.textContent;
  }

}

customElements.define(COMPONENT_NAME, ProgressSpinner);
