export const cadenzaTopWindow = getCadenzaTopWindow();

/*
 * Note:
 * Traversing window.parent until it's equal to window.self doesn't seem to work.
 * Hence we assume a max. iframe nesting level of 2.
 */
function getCadenzaTopWindow () {
  const origin = window.location.origin;
  const contextPath = window.Disy?.contextPath;
  try {
    // IE and Edge allow us to access the location.origin of parent windows ...
    if (isCadenzaWindow(window.parent.parent, origin, contextPath)) {
      return window.parent.parent;
    } else if (isCadenzaWindow(window.parent, origin, contextPath)) {
      return window.parent;
    } else {
      return window;
    }
  } catch (error) {
    // ... while Chrome and Firefox throw an exception (also for other APIs of the other window).
    try {
      if (isCadenzaWindow(window.parent, origin, contextPath)) {
        return window.parent;
      }
      return window;
    } catch (error) {
      return window;
    }
  }
}

function isCadenzaWindow (parentWindow: Window, origin: string, contextPath: string) {
  const { Disy, location: parentLocation } = parentWindow;
  return Disy != null && parentLocation.origin === origin && parentLocation.pathname.startsWith(contextPath);
}
